<template>
    <div class="content-main">
        <div>
            <div class="content-header">
                <h2>勘误表</h2>
            </div>
            <div>
                <el-divider></el-divider>
            </div>
        </div>
        <div class="content-title">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
                <el-breadcrumb-item>勘误表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="content-notice">
            卡片的勘误是指因各种原因，卡片描述与实际印刷在卡片上的数字、文字等有所不同。无论是在官方还是非官方赛事上，发布的勘误均有效。出现较为重大的勘误时，我们会交换产品。我们为勘误对各位玩家和相关方造成的不便深表歉意。敬请详细阅读具体的勘误内容。
        </div>

        <div class="eratta-list">
            <el-row class="eratta-list-th">
                <el-col :span="4">更新日期</el-col>
                <el-col :span="6">产品名称</el-col>
                <el-col :span="8">卡片编号</el-col>
                <el-col :span="6">卡片名称</el-col>
            </el-row>

            <el-row class="erattr-list-tr" v-for="item in tableData" :key="item">
                <el-col :span="4">{{item.update}}</el-col>
                <el-col :span="6">{{item.title}}</el-col>
                <el-col :span="8">{{item.card_no}}</el-col>
                <el-col :span="6">
                    <router-link
                            :to="{path:'/index/erattaInfo',query:{id:item.id,info:item}}"
                    >
                        {{item.card_name}}
                    </router-link>
                </el-col>
            </el-row>
        </div>

        <div style="padding:20px 0;width: 60%;margin: 0 auto">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="pageSizes"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "list",
        data:function () {
            return {
                pageSize:this.$webConfig.pages.pageSize,
                pageSizes:this.$webConfig.pages.pageSizes,
                currentPage:1,
                tableData: [],
                total:0
            }
        },
        methods: {
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
                this.currentPage = val;
                this.getList();
            },
            getList(){
                let page = this.currentPage;
                let limit = this.pageSize;
                this.$axios.post("site/siteEratta/getList",{page,limit})
                    .then((res) => {
                        if (res.data.code == 1000){
                            this.tableData = res.data.data.list;
                            this.total = res.data.data.total;
                        }
                    })
            }
        },
        created() {
            this.getList();
        }
    }
</script>

<style scoped>

    .content-main{
        width: 100%;
        margin: 0 auto;
        background-color: white;
        padding: 20px 0;
    }
    .content-header{
        text-align: center;
    }
    .content-title{
        width: 60%;
        margin: 0 auto;
    }
    .content-notice{
        padding: 30px 100px 0 100px;
        margin: 0 auto;
        width: 50%;
        color: #525252;
        font-weight: 100;
        line-height: 26px;
        letter-spacing: 1px;

    }
    .eratta-list{
        width: 60%;
        margin: 30px auto;
        /*text-align: center;*/
    }
    .eratta-list-th{
        font-weight: bold;
        font-size: 18px;
        height: 30px;
        border-bottom: 2px solid #aeaeae;
    }
    .erattr-list-tr{

        font-size: 18px;
        border-bottom: 1px solid #eeeeee;
        padding: 8px 8px;
    }

</style>
